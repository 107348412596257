.photo-2023-09-21-16-08-29-icon {
  position: relative;
  width: 325px;
  height: 450px;
  object-fit: cover;
  
}
.fluentarrow-next-20-regular-icon {
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  flex-shrink: 0;
}
.previous {
  position: relative;
  margin-left: -2px;
}
.fluentarrow-next-20-regular-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.next {
  position: relative;
}
.frame-parent1 {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 102.5px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 68px;
}
.frame-wrapper {
  position: relative;
  border-radius: 30px;
  background-color: var(--color-white);
  box-shadow: 0 52px 15px transparent, 0 34px 13px rgba(0, 0, 0, 0.01),
    0 19px 11px rgba(0, 0, 0, 0.05), 0 8px 8px rgba(0, 0, 0, 0.09),
    0 2px 5px rgba(0, 0, 0, 0.1);
  width: 325px;
  height: 50px;

  overflow: hidden;
  flex-shrink: 0;
  
}
.photo-2023-09-21-16-08-29-parent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: justify;
  font-size: var(--font-size-sm);
  color: var(--color-dimgray-200);
  font-family: var(--font-roboto);
}
