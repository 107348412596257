@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-roboto: Roboto;
  --font-montserrat: Montserrat;

  /* font sizes */
  --font-size-5xs: 8px;
  --font-size-sm: 14px;
  --font-size-smi: 13px;
  --font-size-xl: 20px;
  --font-size-mini: 15px;
  --font-size-xs: 12px;

  /* Colors */
  --color-white: #fff;
  --color-black: #000;
  --color-limegreen: #36df47;
  --color-dimgray-100: #606060;
  --color-dimgray-200: #585858;
  --color-gainsboro: #d9d9d9;
  --color-slateblue: #4c3cac;
  --color-steelblue-100: #005c84;
  --color-steelblue-200: #005980;
  --color-steelblue-300: rgba(19, 97, 131, 0.52);
  --color-darkgray: #a19e9e;
  --color-gray-100: #252525;

  /* Gaps */
  --gap-mini: 15px;
  --gap-3xs: 10px;
  --gap-sm: 14px;
  --gap-2xs: 11px;
  --gap-11xs: 2px;

  /* Border radiuses */
  --br-3xs: 10px;
  --br-9xs: 4px;
  --br-xl: 20px;
  --br-7xs: 6px;
  --br-mid: 17px;
  --br-5xs: 8px;
  --br-13xl: 32px;
}
