.icbaseline-phone-icon {
  position: relative;
  width: 19px;
  height: 19px;
  overflow: hidden;
  flex-shrink: 0;
}
.icbaseline-phone-parent {
  position: absolute;
  top: 642px;
  left: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 9px;
}
.dan-logo-1 {
  position: absolute;
  top: 10px;
  left: 0;
  width: 139px;
  height: 78px;
  object-fit: cover;
}
.iphone-13-mini-4-child {
  position: absolute;
  top: 107px;
  left: calc(50% - 150.5px);
  border-radius: 21px;
  width: 302px;
  height: 337px;
  object-fit: cover;
}
.daniel-hailu{
  position: relative;
  text-transform: capitalize;
}
.daniel-hailuda{
  position: relative;
  text-transform: capitalize;
  margin-left: -70px;
}
.bini{
  position: relative;
  text-transform: capitalize;
  margin-left: -20px;
}
.solo
{
  position: relative;
  text-transform: capitalize;
  margin-left: -30px;
}
.solomek
{
  position: relative;
  text-transform: capitalize;
  margin-left: -50px;
}

.founder-ceo {
  position: relative;
  text-transform: capitalize;
  font-size: var(--font-size-sm);
  color: #7d7d7d;
}
.founder-ceozela{
  position: relative;
  text-transform: capitalize;
  font-size: var(--font-size-sm);
  color: #7d7d7d;
  margin-left: -20px;
}
.founder-ceomek {
  position: relative;
  text-transform: capitalize;
  font-size: var(--font-size-sm);
  color: #7d7d7d;
  margin-left: -50px;
}
.founder-ceoo {
  position: relative;
  text-transform: capitalize;
  font-size: var(--font-size-sm);
  color: #7d7d7d;
  margin-left: -66px;
}
.founder-ceob{
  position: relative;
  text-transform: capitalize;
  font-size: var(--font-size-sm);
  color: #7d7d7d;
  margin-left: -25px;
}
.daniel-hailu1{
  position: relative;
  text-transform: capitalize;
  margin-left: 40px;
  color: #005980;
}
.daniel-hailu12{
  position: relative;
  text-transform: capitalize;
  
  color: #005980;
  margin-left: 70px;
}

.daniel-hailu-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.examplegmailcom {
  position: relative;
  text-transform: capitalize;
  font-weight: 500;
}
.examplegmailcommek {
  position: relative;
  text-transform: capitalize;
  font-weight: 500;
  margin-left: -50px;
}
.examplegmailcomzela {
  position: relative;
  text-transform: capitalize;
  font-weight: 500;
  margin-left: -20px;
}
.examplegmailcomy {
  position: relative;
  text-transform: capitalize;
  font-weight: 500;
  margin-left: 80px;
}
.examplegmailcomf {
  position: relative;
  text-transform: capitalize;
  font-weight: 500;
  margin-left: -20px;

}

.examplegmailcom-parent,
.frame-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.examplegmailcom-parent {
  gap: var(--gap-mini);
  font-size: var(--font-size-xs);
  color: var(--color-steelblue-100);
}
.examplegmailcom-parentda {
  gap: var(--gap-mini);
  font-size: var(--font-size-xs);
  color: var(--color-steelblue-100);
  margin-left: -60px;
}
.frame-parent {
  position: absolute;
  top: 467px;
  left: calc(50% - 108.5px);
  gap: 21px;
  font-size: 34px;
  color: #2c2c2c;
}
.browse-icon {
  position: absolute;
  top: 730px;
  left: calc(50% + 139.5px);
  border-radius: 50%;
  width: 45px;
  height: 45px;
  cursor: pointer;
}
.dan-global,
.sister-companies,
.yeemma-injera {
  position: absolute;
  text-transform: capitalize;
  display: inline-block;
}
.sister-companies {
  top: 2868px;
  left: calc(50% - 154.5px);
  font-size: var(--font-size-xl);
  width: 230px;
}
.dan-global,
.yeemma-injera {
  top: 2918px;
  left: 15px;
  width: 78px;
}
.yeemma-injera {
  top: 3139px;
  left: 261px;
  width: 103px;
}
.lorem-ipsum-dolor
{
  position: absolute;
  top: 2947px;
  left: calc(50% - 172.5px);
  font-size: var(--font-size-sm);
  font-family: var(--font-roboto);
  color: var(--color-dimgray-100);
  text-align: justify;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  width: 200px;
}
.lorem-ipsum-dolor1 {
  position: absolute;
  top: 3168px;
  
  font-size: var(--font-size-sm);
  font-family: var(--font-roboto);
  color: var(--color-dimgray-100);
  text-align: justify;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  left: calc(50% - 50px);
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  width: 216px;
}

.iphone-13-mini-4-item {
  position: absolute;
  top: 2722px;
  left: calc(50% + 394.5px);
  background-color: var(--color-gainsboro);
  width: 101px;
  height: 126px;
}
.ye-ema-injera-1 {
  position: relative;
  width: 79px;
  height: 46px;
  object-fit: cover;
}
.ye-ema-injera-1-wrapper {
  position: absolute;
  top: 3173px;
  left: calc(50% - 179.5px);
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  box-shadow: -21px 29px 10px transparent, -13px 18px 9px rgba(0, 0, 0, 0.01),
    -8px 10px 8px rgba(0, 0, 0, 0.05), -3px 5px 6px rgba(0, 0, 0, 0.09),
    -1px 1px 3px rgba(0, 0, 0, 0.1);
  width: 92px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 6px;
  box-sizing: border-box;
}
.iphone-13-mini-4-inner {
  position: absolute;
  top: 2936px;
  left: calc(50% + 48.5px);
  border-radius: var(--br-5xs);
  width: 139px;
  height: 168px;
  object-fit: cover;
}
.eaglets-future {
  position: relative;
  text-transform: capitalize;
  display: inline-block;
  width: 103px;
}
.lorem-ipsum-dolor2 {
  margin-top: -3px;
  position: relative;
  font-size: 15px;
  font-family: var(--font-roboto);
  color: var(--color-dimgray-100);
  text-align: justify;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  width: 195px;

}
.eaglets-future-parent {
  width: 205px;
  height: 202px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.eaglets-futue-logo-1-icon {
  position: relative;
  width: 144px;
  height: 38px;
  object-fit: cover;
}
.eaglets-futue-logo-1-wrapper {
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  box-shadow: 45px 37px 16px transparent,
    29px 23px 15px rgba(120, 120, 120, 0.01),
    16px 13px 13px rgba(120, 120, 120, 0.05),
    7px 6px 9px rgba(120, 120, 120, 0.09), 2px 1px 5px rgba(120, 120, 120, 0.1);
  width: 148px;
  height: 163px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 62px 0;
  box-sizing: border-box;
}
.frame-group {
  position: absolute;
  top: 3320px;
  left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.frame-child {
  position: relative;
  border-radius: var(--br-mid);
  width: 30px;
  height: 1.93px;
}
.frame-inner,
.frame-item {
  position: relative;
  border-radius: 50%;
  background-color: var(--color-steelblue-300);
  width: 2px;
  height: 2px;
}
.frame-inner {
  width: 3px;
  height: 3px;
}
.vector-icon {
  position: relative;
  border-radius: var(--br-mid);
  width: 29px;
  height: 2px;
}
.vector-parent {
  position: absolute;
  top: 616px;
  left: calc(50% - 44.5px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.contact-us,
.div1,
.infodanenergyethiopiacom,
.wwwdanenergyethiopiacom {
  position: absolute;
  text-transform: capitalize;
  font-weight: 500;
  color: var(--color-gray-100);
}
.contact-us {
  top: 612px;
  left: 26px;
  font-size: var(--font-size-sm);
}
.div1,
.infodanenergyethiopiacom,
.wwwdanenergyethiopiacom {
  top: 644px;
  left: 80px;
 
}
.infodanenergyethiopiacom,
.wwwdanenergyethiopiacom {
  top: 672px;
  left: 84px;
  text-transform: lowercase;
  
}
.wwwdanenergyethiopiacom {
  top: 700px;
  left: 83px;
  
}
.cmc-road-noc {
  margin: 0;
  font-weight: 500;
}
.blank-line {
  margin: 0;
  text-decoration: underline;
}
.cmc-road-noc-container {
  position: absolute;
  top: 724px;
  left: 84px;
  text-transform: lowercase;
  color: var(--color-gray-100);
  display: inline-block;
  width: 221px;
  height: 28px;
}
.burj-khalifa-149 {
  margin-top: 5px;
  color: #A19E9E;
font-family: Montserrat;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;
}
.burj-khalifa-149-container {
  top: 761px;
  left: 84px;
  text-transform: lowercase;
  color: var(--color-gray-100);
  width: 271px;
}
.burj-khalifa-149-container,
.focus-area,
.lorem-ipsum-dolor3 {
  position: absolute;
  display: inline-block;
}
.focus-area {
  top: 2105px;
  left: 38px;
  font-size: 25px;
  text-transform: capitalize;
  width: 180px;
}
.lorem-ipsum-dolor3 {
  top: 2130px;
  left: calc(50% - 153.5px);
  font-size: var(--font-size-smi);
  font-family: var(--font-roboto);
  color: var(--color-dimgray-100);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 315px;
  height: 17px;
}
.about-the-company {
  position: relative;
  text-transform: capitalize;
  display: inline-block;
  width: 237px;
  flex-shrink: 0;
}
.rectangle-div {
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--color-slateblue);
  width: 60px;
  height: 7px;

}
.about-the-company-parent {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-11xs);
  color: var(--color-black);
}
.imgaff{
  height: 250px;
  width: 300px;
}
.hidden {
  display: none;
}

.vivamimot{
  margin-top: -180px;
  position: absolute;

}

.lorem-ipsum-dolor-container {
  position: absolute;
  left: 0;
  font-size: var(--font-size-mini);
  text-transform: capitalize;
  display: inline-block;
}
.read-more1 {
  position: absolute;
 margin-top: 160px;
  font-size: var(--font-size-mini);
  text-transform: capitalize;
  display: inline-block;
  border: none;
  background-color: white;
  color: blue ;
  font-weight: 700;

}
.lorem-ipsum-dolor-container {
  top: 24px;
  font-weight: 500;
  color: var(--color-darkgray);
  width: 301px;
  height: 143px;
}

.frame-child1 {
  position: relative;
  border-radius: var(--br-7xs);
  width: 143.5px;
  height: 122px;
  z-index: 0;
}
.image-1-icon {
  position: absolute;
  margin: 0 !important;
  top: 31px;
  left: 54px;
  width: 67px;
  height: 60px;
  object-fit: cover;
  z-index: 1;
}
.vector-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xs);
}
.lorem-ipsum-dolor5 {
  position: relative;
  font-size: var(--font-size-smi);
  font-family: var(--font-roboto);
  color: var(--color-dimgray-100);
  text-align: center;
  display: -webkit-inline-box;
  width: 180px;
}
.vision-parent {
  position: absolute;
  top: 201px;
  left: -29px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-2xs);
}
.frame-child2 {
  position: absolute;
  top: -3px;
  left: -33px;
  border-radius: var(--br-7xs);
  width: 147px;
  height: 122px;
}
.images-2-icon {
  position: absolute;
  top: 30px;
  left: 29px;
  width: 54px;
  height: 39px;
  object-fit: cover;
}
.vector-container {
  position: relative;
  width: 112px;
  height: 99px;
  margin-left: 60px;
}
.vector-container1 {
  position: relative;
  width: 112px;
  height: 99px;
  margin-left: 5px;
}
.lorem-ipsum-dolor6 {
  position: relative;
  font-size: var(--font-size-smi);
  font-family: var(--font-roboto);
  color: var(--color-dimgray-100);
  text-align: center;
 margin-left: 20px;
  width: 100px;
}
.motto-parent {
  position: absolute;
  top: 441px;
  left: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.frame-child3 {
  position: absolute;
  top: -3px;
  left: -29.5px;
  border-radius: var(--br-7xs);
  width: 143.5px;
  height: 122px;
}
.mission-target-icon-or-busines {
  position: absolute;
  top: 20px;
  left: 23px;
  width: 64.96px;
  height: 60px;
  object-fit: cover;
}
.lorem-ipsum-dolor7 {
 position: relative;
  font-size: var(--font-size-smi);
  font-family: var(--font-roboto);
  color: var(--color-dimgray-100);
  text-align: center;
  display: -webkit-inline-box;
  width: 180px; 
  margin-left: 10px;
}
.lorem-ipsum-dolor-sit-amet-co-wrapper,
.mission-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.lorem-ipsum-dolor-sit-amet-co-wrapper {
  text-align: center;
  font-size: var(--font-size-smi);
  color: var(--color-dimgray-100);
  font-family: var(--font-roboto);
}
.mission-parent {
  position: absolute;
  top: 441px;
  left: -29px;
  gap: var(--gap-sm);
}
.frame-icon {
  position: relative;
  width: 147px;
  height: 122px;
}
.lorem-ipsum-dolor8 {
  position: relative;
  font-size: var(--font-size-smi);
  font-family: var(--font-roboto);
  color: var(--color-dimgray-100);
  text-align: center;
  display: -webkit-inline-box;
  width: 180px;
  margin-left: 10px;
}
.values-parent {
  position: absolute;
  top: 203px;
  left: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.read-more {
  position: relative;
  width: 301px;
  height: 222px;
  
}
.read-more-wrapper {
  position: absolute;
  top: 863px;
  left: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 34px 0;
  font-size: var(--font-size-xl);
  color: var(--color-steelblue-200);
 
}
.vis{
position: relative;
  top: 1000px;
}
.mask-group-icon {
  position: absolute;
  top: 1592.58px;
  left: calc(50% - 187.5px);
  width: 375px;
  height: 481.62px;
  object-fit: cover;
}
.iphone-13-mini-4-child1,
.mask-group-icon1 {
  position: absolute;
  top: 2558px;
  left: 62px;
  width: 302.5px;
  height: 144px;
  object-fit: cover;
}
.iphone-13-mini-4-child1 {
  top: 2663.5px;
  left: 10.5px;
  border-radius: 14px;
  width: 313.5px;
  height: 155px;
}
.f1jwrsgwyaa-4cx-1-icon {
  position: absolute;
  height: 7.21%;
  width: 149.94%;
  top: 62%;
  right: -253.14%;
  bottom: 30.79%;
  left: 203.2%;
  border-radius: var(--br-3xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.anima-child {
  position: absolute;
  top: 10px;
  left: -69px;
  border-radius: var(--br-xl);
  background-color: var(--color-slateblue);
  width: 60px;
  height: 7px;
}
.anima {
  position: absolute;
  top: 2868px;
  left: 268px;
  width: 80px;
  height: 27px;
  overflow: hidden;
}
.union-child,
.union-item {
  position: absolute;
  background-color: var(--color-gainsboro);
}
.union-child {
  top: 3586px;
  left: calc(50% - 153.5px);
  width: 341px;
  height: 688px;
}
.union-item {
  top: 3564px;
  left: 17px;
  width: 84px;
  height: 27px;
}
.union {
  position: relative;
  border-radius: 31px;
  background-color: var(--color-white);
  box-shadow: 0 37px 10px transparent, 0 24px 9px rgba(94, 94, 94, 0.01),
    0 13px 8px rgba(94, 94, 94, 0.05), 0 6px 6px rgba(94, 94, 94, 0.09),
    0 1px 3px rgba(94, 94, 94, 0.1);
  width: 341px;
  height: 710px;
}
.gallery {
  position: absolute;
  top: 3574px;
  left: 41px;
  font-size: var(--font-size-sm);
  font-family: var(--font-roboto);
  color: #676767;
  text-align: justify;
}
.download-1-icon,
.dsc09779-11zon-yqealc-1-icon,
.photo-1-2023-09-22-15-55-06-icon,
.photo-5-2023-09-21-17-49-34-icon {
  position: absolute;
  height: 25.96%;
  width: 67.27%;
  top: 0;
  right: 32.73%;
  bottom: 74.04%;
  left: 0;
  border-radius: var(--br-9xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.download-1-icon,
.dsc09779-11zon-yqealc-1-icon,
.photo-5-2023-09-21-17-49-34-icon {
  height: 24.22%;
  width: 92.19%;
  top: 26.83%;
  right: 3.9%;
  bottom: 48.95%;
  left: 3.9%;
}
.download-1-icon,
.dsc09779-11zon-yqealc-1-icon {
  height: 29.09%;
  width: 31.53%;
  top: 4.18%;
  right: 0.6%;
  bottom: 66.72%;
  left: 67.87%;
}
.dsc09779-11zon-yqealc-1-icon {
  height: 23%;
  width: 76.88%;
  top: 51.92%;
  right: 0;
  bottom: 25.09%;
  left: 23.12%;
}
.photo-2022-10-10-10-50-19-3-11-icon,
.photo-3-2023-09-22-16-03-28-icon {
  position: absolute;
  height: 24.22%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.photo-3-2023-09-22-16-03-28-icon {
  width: 61.26%;
  top: 46.86%;
  right: 39.94%;
  bottom: 28.92%;
  left: -1.2%;
  border-radius: var(--br-9xs);
}
.photo-2022-10-10-10-50-19-3-11-icon {
  width: 96.7%;
  top: 75.78%;
  right: 2.7%;
  bottom: 0;
  left: 0.6%;
}
.photo-1-2023-09-22-15-55-06-parent {
  position: absolute;
  top: 3622px;
  left: 25px;
  width: 333px;
  height: 574px;
}
.carbonmaximize-icon {
  position: absolute;
  top: 4227px;
  left: 303px;
  width: 32px;
  height: 32px;
  overflow: hidden;
  cursor: pointer;
}
.frame-child4,
.frame-child5,
.frame-child6,
.frame-child7 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-limegreen);
  width: 10px;
  height: 10px;
}
.frame-child5,
.frame-child6,
.frame-child7 {
  top: 1px;
  left: 216px;
}
.frame-child6,
.frame-child7 {
  top: 25px;
  left: 0;
}
.frame-child7 {
  top: 26px;
  left: 216px;
}
.frame-child10,
.frame-child11,
.frame-child12,
.frame-child13,
.frame-child14,
.frame-child8,
.frame-child9 {
  position: absolute;
  top: 50px;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-limegreen);
  width: 10px;
  height: 10px;
}
.frame-child10,
.frame-child11,
.frame-child12,
.frame-child13,
.frame-child14,
.frame-child9 {
  top: 51px;
  left: 216px;
}
.frame-child10,
.frame-child11,
.frame-child12,
.frame-child13,
.frame-child14 {
  top: 75px;
  left: 0;
}
.frame-child11,
.frame-child12,
.frame-child13,
.frame-child14 {
  top: 100px;
}
.frame-child12,
.frame-child13,
.frame-child14 {
  top: 125px;
}
.frame-child13,
.frame-child14 {
  top: 150px;
}
.frame-child14 {
  top: 175px;
}
.engineering-service,
.training-placement {
  position: absolute;
  top: 1px;
  left: 14px;
}
.engineering-service {
  left: 230px;
}
.it-services,
.research-development,
.talent-hunt {
  position: absolute;
  top: 26px;
  left: 230px;
}
.it-services,
.talent-hunt {
  left: 14px;
}
.it-services {
  top: 75px;
}
.development-of-msmes,
.startups-incubation {
  position: absolute;
  top: 101px;
  left: 14px;
}
.startups-incubation {
  top: 126px;
}
.digital-marketing,
.fintec {
  position: absolute;
  top: 150px;
  left: 15px;
}
.fintec {
  top: 176px;
}
.manufacturingagri-tech-cons {
  position: absolute;
  top: 50px;
  left: 14px;
}
.ellipse-parent,
.frame {
  position: relative;
  width: 319px;
  height: 180px;
}
.frame {
  position: absolute;
  top: 18px;
  left: 18px;
  height: 585px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.line-icon {
  position: absolute;
  top: 23.5px;
  left: 346px;
  width: 3px;
  height: 268px;
}
.bagra{
  margin-top: 4px;
  text-transform: capitalize;
  justify-content: center;
  gap: var(--gap-11xs);
  color: var(--color-black);
}
.bagra1{
  margin-top: -10px;
}
.frame-container {
  position: absolute;
  top: 2150px;
  left: calc(50% - 176.5px);
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  box-shadow: -27px 63px 19px transparent,
    -17px 40px 17px rgba(111, 109, 109, 0.01),
    -10px 23px 15px rgba(111, 109, 109, 0.05),
    -4px 10px 11px rgba(111, 109, 109, 0.09),
    -1px 3px 6px rgba(111, 109, 109, 0.1);
  width: 354px;
  height: 380px;
  overflow-y: auto;
  text-align: center;
  font-size: var(--font-size-16xs);
  font-family: var(--font-roboto);
}
.mask-groupvariant2-icon {
  position: absolute;
  top: 1578px;
  left: calc(50% - 187.5px);
  width: 375px;
  height: 481.62px;
  object-fit: cover;
}
.iphone-13-mini-4 {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 4305px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-black);
  font-family: var(--font-montserrat);
}
.hello {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  
}

.hello.expanded {
  max-height: 500px; /* Adjust the value as needed */
}